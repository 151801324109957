import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'ToMinPro',
    component: () => import('@/views/toMinPro.vue')
  },
  {
    path: '/miniPro',
    name: 'ToMinPro',
    component: () => import('@/views/toMinPro.vue')
  },
  {
    path: '/Cny',
    name: 'Cny',
    component: () => import('@/views/toCny.vue')
  },
  // 白牌
  {
    path: '/whiteCard',
    name: 'whiteCard',
    component: () => import('@/views/toWhiteCard.vue')
  },
  // 活码H5扫码落地页
  {
    path: '/liveCode',
    name: 'liveCode',
    component: () => import('@/views/toLiveCode.vue')
  },
  // 活码小程序内打开的海报H5页面
  {
    path: '/poster/:code',
    name: 'poser',
    component: () => import('@/views/PosterView.vue')
  },
  {
    path: '/trenchCode',
    name: 'trenchCode',
    component: () => import('@/views/toTrenchCode.vue')
  },
  // ai 外呼短信短链H5
  {
    path: '/aiCall/:aiCode',
    name: 'aiCall',
    component: () => import('@/views/AICall.vue')
  },
  // H5跳转扭蛋机链接
  {
    path: '/lucky/:luckyId?',
    name: 'lucky',
    component: () => import('@/views/toLucky.vue')
  },
  // 打开AI外呼另一个福利官海报落地页
  {
    path: '/toAiCallPoster/:posterId?',
    name: 'toAiCallPoster',
    component: () => import('@/views/toAiCallPoster.vue')
  },
  // 打开AI外呼京东福利官海报落地页
  {
    path: '/toJDAiCall/:posterId?',
    name: 'toJDAiCall',
    component: () => import('@/views/toJDAiCall.vue')
  },
  {
    path: '/:pathMatch(.*)',
    component: () => import('@/views/toMinPro.vue')
  }
]

const router = createRouter({
  history: createWebHistory(''),
  routes
})

export default router
